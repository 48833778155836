<template>
  <div>
    <div class="content">
      <div class="all">
        <div class="table" v-if="!dialogVisible">
          <!--搜索框-->
          <el-form ref="search" :inline="true" :model="search" size="small">
            <el-form-item label="安检计划">
              <el-select
                v-model="zhouqiId"
                clearable
                collapse-tags
                placeholder="请选择"
                size="mini"
                multiple
                filterable
              >
                <el-option
                  v-for="item in periodLists"
                  :key="item.id"
                  :label="item.periodName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="安检类型">
              <el-select
                v-model="taskTypes"
                clearable
                collapse-tags
                placeholder="请选择"
                size="mini"
                multiple
                filterable
              >
                <el-option
                  v-for="item in checkTypes"
                  :key="item.id"
                  :label="item.label"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="用户分类">
              <el-select
                v-model="search.userType"
                clearable
                filterable
                placeholder="请选择"
                size="mini"
              >
                <el-option
                  v-for="item in userTypes"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="安检员">
              <el-input
                v-model="search.inspectorName"
                size="mini"
                clearable
                placeholder="关键字"
                class="EquipmentMarginLeft"
              ></el-input>
            </el-form-item>
            <el-form-item label="状态">
              <el-select
                v-model="search.finishType"
                clearable
                filterable
                placeholder="请选择"
                size="mini"
              >
                <el-option
                  v-for="item in statusList"
                  :key="item.id"
                  :label="item.label"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="任务名称">
              <el-input
                v-model="search.taskName"
                size="mini"
                clearable
                placeholder="关键字"
                class="EquipmentMarginLeft"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <div>
                <el-button type="primary" size="small" @click="getList"
                  >查询</el-button
                >
                <el-button type="warning" size="small" @click="reset"
                  >重置</el-button
                >
              </div>
            </el-form-item>
          </el-form>
          <div style="display: flex">
            <el-button
                v-if="$anthButtons.getButtonAuth('jmrwtj')"
              type="text"
              style="
                border: 1px solid #1082ff;
                color: #1082ff;
                padding: 0 14px;
                height: 30px;
              "
              plain
              @click="addItem(1)"
              size="small"
              >添加</el-button
            >
          </div>
          <!--数据表格-->
          <el-table
            ref="tableData"
            :data="tableData"
            style="width: 100%; margin: 15px 0"
            height="calc(100vh - 365px)"
            :stripe="true"
            :header-cell-style="{
              color: '#333',
              fontFamily: 'MicrosoftYaHeiUI',
              fontSize: '14px',
              fontWeight: 900,
              textAlign: 'left',
              background: '#f8f8f9',
            }"
          >
            <el-table-column type="index" label="序号" width="70">
            </el-table-column>
            <el-table-column
              prop="periodName"
              label="安检计划"
              width="120"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="taskCategory"
              label="安检类型"
              width="120"
              show-overflow-tooltip
            >
              <template slot-scope="{ row }">
                {{row.taskCategory == 1 ? '常规区域安检' : row.taskCategory == 2 ? '常规散户安检' : '异常安检'}}
              </template>
            </el-table-column>
            <el-table-column
              prop="taskName"
              label="任务名称"
              width="120"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="userTypeName"
              label="用户分类"
              show-overflow-tooltip
              width="100"
            >
            </el-table-column>
            <el-table-column
              prop="templateName"
              label="采集模板"
              show-overflow-tooltip
              width="100"
            >
            </el-table-column>
            <el-table-column
              prop="scuTemplateName"
              label="安检模板"
              show-overflow-tooltip
              width="100"
            >
            </el-table-column>
            <el-table-column
              prop="inspectorNames"
              label="安检员"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column prop="houseCount" label="总户数" width="80">
            </el-table-column>
            <el-table-column
              prop="completeCount"
              label="已检查数"
              width="80"
            ></el-table-column>
            <el-table-column label="已检查率" width="80">
              <template slot-scope="{ row }">{{
                row.houseCount ? (row.completeCount / row.houseCount * 100).toFixed(2) + "%" : '0.00' + '%'
              }}</template>
            </el-table-column>
            <el-table-column
              prop="doneCount"
              label="已完成数"
              width="80"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column label="已完成率" width="80" show-overflow-tooltip>
              <template slot-scope="{ row }">{{
                row.houseCount ? (row.doneCount / row.houseCount * 100).toFixed(2) + "%" : '0.00' + '%'
              }}</template>
            </el-table-column>
            <el-table-column
              prop="startTime"
              label="任务时间"
              show-overflow-tooltip
              width="180"
            >
              <template slot-scope="{ row }">
                {{ row.startTime.split(" ")[0].replaceAll("-", "/") }}-{{
                  row.endTime.split(" ")[0].replaceAll("-", "/")
                }}
              </template>
            </el-table-column>
            <el-table-column
              prop="createTime"
              label="添加时间"
              show-overflow-tooltip
              width="150"
            >
            </el-table-column>
            <el-table-column
              prop="finishType"
              label="状态"
              show-overflow-tooltip
              width="60"
               fixed="right"
            >
              <template slot-scope="{ row }">
                {{
                  row.finishType == 1
                    ? "未开始"
                    : row.finishType == 2
                    ? "进行中"
                    : "已结束"
                }}
              </template>
            </el-table-column>
            <el-table-column prop="operation" label="操作" width="233px" fixed="right">
              <template slot-scope="{ row }">
                <el-button type="primary" size="small" @click="addItem(3, row)"
                     v-if="$anthButtons.getButtonAuth('jmrwck')"
                  >查看</el-button
                >
                <!-- 未开始和进行中的任务可编辑，可编辑的内容不同，点击分别打开不同的编辑页 -->
                <el-button
                  type="primary"
                  size="small"
                  @click="addItem(2, row)"
                  v-if="(row.finishType == 1 || row.finishType == 2) && $anthButtons.getButtonAuth('jmrwbj')"
                  >编辑</el-button
                >
                <!-- v-if="row.status == '已登记'" -->
                <!-- 仅有未开始的单次任务可删除，点击进行提示 -->
                <el-button
                  type="danger"
                  size="mini"
                  v-if="row.finishType == 1 && row.taskType == 1 && $anthButtons.getButtonAuth('jmrusc')"
                  style="background-color: #ee2d2d"
                  @click="delItem(row)"
                  >删除</el-button
                >
                <el-button
                  type="danger"
                  size="mini"
                  v-if="row.finishType == 2 && $anthButtons.getButtonAuth('jmrwgb')"
                  style="background-color: #ee2d2d"
                  @click="closeItem(row)"
                  >关闭</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <div class="pageBox">
            <el-pagination
              :current-page="search.current"
              :background="true"
              :page-sizes="[30, 50, 100]"
              :page-size="search.size"
              layout="total, prev, pager, next, sizes, jumper"
              :total="total"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            >
            </el-pagination>
          </div>
        </div>
        <!-- <div v-show="dialogVisible">
          <reAdd
            :titleStatus="titleStatus"
            @close="close"
            ref="desList"
          ></reAdd>
        </div> -->
      </div>
    </div>

    <!-- 编辑进行中的任务 -->
    <el-dialog
      :visible.sync="editConductVisible"
      :close-on-click-modal="false"
      width="30%"
    >
      <el-form
        ref="editConductform"
        :model="editConductform"
        label-width="80px"
        :rules="editConductRules"
      >
        <!-- <el-form-item label="安检周期" prop="periodId">
          <el-select
            style="width: 400px"
            v-model="editConductform.periodId"
            placeholder="请选择"
            clearable
            filterable
          >
            <el-option
              v-for="item in periodLists"
              :key="item.id"
              :label="item.periodName"
              :value="parseInt(item.id)"
            >
            </el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="安检名称" prop="taskName">
          <el-input
            v-model="editConductform.taskName"
            size="small"
            style="width: 400px"
          ></el-input>
        </el-form-item>
        <el-form-item label="采集模板" prop="tplId">
          <el-select
            style="width: 400px"
            v-model="editConductform.tplId"
            placeholder="请选择"
            clearable
            filterable
          >
            <el-option
              v-for="item in colList"
              :key="item.id"
              :label="item.tplName"
              :value="parseInt(item.id)"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="安检模板" prop="scuTplId">
          <el-select
            style="width: 400px"
            v-model="editConductform.scuTplId"
            placeholder="请选择"
            clearable
            filterable
          >
            <el-option
              v-for="item in checkList"
              :key="item.id"
              :label="item.tplName"
              :value="parseInt(item.id)"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="安检员" prop="inspectorIds">
          <el-select
            style="width: 400px"
            v-model="editConductform.inspectorIds"
            placeholder="请选择"
            clearable
            multiple
            filterable
          >
            <el-option
              v-for="item in checkerList"
              :key="item.id"
              :label="item.realname"
              :value="parseInt(item.id)"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="任务时间"
          prop="startTime"
          style="margin-top: 24px"
        >
          <el-date-picker
            v-model="editConductTimes"
            type="daterange"
            range-separator="至"
            placeholder="请选择时间段"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
            @change="setDate"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editConductVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveData">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 选择安检类型 -->
    <el-dialog
      class="custom-dialog"
      :visible.sync="choseDialog"
      :close-on-click-modal="false"
      width="20%"
    >
      <span slot="title">选择安检类型</span>
      <div class="choseContain">
        <el-button @click="toAdd(1)">常规区域安检</el-button>
        <el-button @click="toAdd(2)">常规散户安检</el-button>
        <el-button @click="toAdd(3)">异常安检</el-button>
      </div>
    </el-dialog>
  </div>
</template>
 
<script>
import reAdd from "./assembly/reAdd";
// import { taskPageList, taskDeleteById } from "@/RequestPort/selectQuery/select";
import {
  arumTaskTaskPageList,
  arumTaskTaskCloseById,
  arumTaskTaskDeleteById,
  arumTaskTemplatePulldown,
  arumTaskScuTemplatePulldown,
  arumTaskTaskUpdate,
  periodList
} from "@/RequestPort/selectQuery/residentRequest.js";
import { arumTaskSelectTaskById } from "@/RequestPort/selectQuery/residentRequest.js";
import {
  dict_selectDictListByPid,
  aaumTask_selectTaskById,
  AaumArchive_inspectorDropDown,
  aaumTask_taskUpdate,
} from "../../RequestPort/securityTask/unResident";
export default {
  name: "resident",
  data() {
    let _this = this;
    return {
      // 搜索条件
      search: {
        current: 1,
        size: 100,
      },
      // findObj: {},
      // 选择时间
      value1: "",
      // 总条数
      total: 0,
      // 用户分类
      userTypes: [],
      // checkerList: [],
      checkTypes: [
        { label: "常规区域安检", id: 1 },
        { label: "常规散户安检", id: 2 },
        { label: "异常安检", id: 3 },
      ],
      // 状态
      statusList: [
        { label: "未开始", id: 1 },
        { label: "进行中", id: 2 },
        { label: "已结束", id: 3 },
      ],
      dialogVisible: false,
      editConductform: {},
      checkerList: [],
      editConductRules: {
        periodId: [
          {
            required: true,
            message: "请选择安检周期",
            trigger: "change",
          },
        ],
        taskName: [
          {
            required: true,
            message: "请填写安检名称",
            trigger: "blur",
          },
        ],
        tplId: [
          {
            required: true,
            message: "请选择采集模板",
            trigger: "change",
          },
        ],
        scuTplId: [
          {
            required: true,
            message: "请选择安检模板",
            trigger: "change",
          },
        ],
        inspectorIds: [
          {
            required: true,
            message: "请选择安检员",
            trigger: "change",
          },
        ],
        startTime: [
          {
            required: true,
            trigger: "change",
            message: "请选择任务时间",
          },
        ],
      },
      // 非居民任务
      tableData: [],
      titleStatus: 1, // 1添加2编辑3查看
      editConductVisible: false,
      loading: true,
      editConductTimes: [],
      times: [],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7; //若是没有后面的-8.64e7就是不能够选择今天的
        }
      },
        // disabledDate(time) {
        //   return time.getTime() < new Date.getTime(editConductform.startTime.replace(/\//g,'-')); //若是没有后面的-8.64e7就是不能够选择今天的
        // },
      colList: [], // 采集模板下拉
      checkList: [], // 安检模板下拉
      periodLists: [], // 安检周期下拉
      zhouqiId: [], // 搜索条件周期id
      taskTypes: [], // 搜索条件安检类型
      choseDialog: false
    };
  },
  mounted() {
    // 获取列表
    this.getList();
    // 用户分类
    this.getuserTypeDropDown();
    AaumArchive_inspectorDropDown({}).then((res) => {
      this.checkerList = res.data;
    });
    this.getPeriod();
  },
  components: { reAdd },
  methods: {
    getPeriod() { // 安检周期下拉
      periodList().then(res => {
        this.periodLists = res.data
      })
    },
    closeItem(item) {
      this.$confirm("是否确认关闭任务", "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        closeOnClickModal:false,
        type: "warning",
      })
          .then(() => {
            arumTaskTaskCloseById({ taskId: item.id }).then((res) => {
              if (res.code === 200) {
                res.data=='关闭失败' ? this.$message.error(res.data) : this.$message.success(res.data);
                this.getList();
              }
            });
          })
          .catch(() => {
            console.log("取消销户");
          });
    },

    fillterName(name) {
      return name.split("(");
    },
    /***
     * 用户分类
     */
    getuserTypeDropDown() {
      //   userTypeDropDown().then((res) => {
      //     this.userTypes = res.data;
      //   });
      dict_selectDictListByPid({ pid: 732 }).then((res) => {
        this.userTypes = res.data;
      });
    },

    /**
     * 获取列表数据
     */
    getList(val) {
      if (val) {
        this.search.current = 1;
      }
      this.search.periodIds = this.zhouqiId.toString();
      this.search.taskCategory = this.taskTypes.toString();
      arumTaskTaskPageList(this.search).then((res) => {
        this.tableData = res.data.records;
        this.total = res.data.total;
      });
    },
    toAdd(type) {
      let add = ''
      if (type===1) {
        add = 'addArea'
      } else if (type === 2) {
        add = 'addRetail'
      } else if (type === 3) {
        add = 'addAbnormal'
      }
      let chakan = this.$router.resolve({
        path: '/home/resident/' + add,      //跳转目标窗口的地址
        query: { } 
      })
      window.open(chakan.href);
    },
    /**
     * 添加页面
     */
    addItem(val, row) {
      this.titleStatus = val
      if (val == 1) {
        let roleIds = localStorage.getItem("roleIds").split(',')
        let index = roleIds.findIndex(el => el == '1')
        if(index >= 0) {
          this.$message.info('系统管理员禁止添加！')
          return
        }
        this.choseDialog = true
        return
      } else if (val == 2 || val == 3) { // 编辑 || 查看
        if (val == 2 && row.finishType == 2) {
          this.editConductVisible = true;
          arumTaskSelectTaskById({ taskId: row.id }).then((res) => {
              arumTaskTemplatePulldown({userType: res.data.userType}).then(innerRes => { // 采集模板
                  this.colList = innerRes.data
                  const index = this.colList.findIndex(item => {return item.id == this.editConductform.tplId})
                  index == -1 ? this.editConductform.tplId = '' : ''
              })
              arumTaskScuTemplatePulldown({userType: res.data.userType}).then(innerRes => { // 安检模板
                  this.checkList = innerRes.data
                  const index = this.checkList.findIndex(item => {return item.id == this.editConductform.scuTplId})
                  // console.log(index)
                  index == -1 ? this.editConductform.scuTplId = '' : ''
              })
            this.editConductform = res.data;
            this.editConductTimes = [res.data.startTime.replace(/\//g,'-'), res.data.endTime.replace(/\//g,'-')];
            this.value1 = res.data.endTime;
          });
        } else {

          if (row.taskCategory == 1) {
            let chakan = this.$router.resolve({
              path: '/home/resident/addArea',      //跳转目标窗口的地址
              query: {id: row.id, titleStatus: this.titleStatus} 
            })
            window.open(chakan.href);
          } else if (row.taskCategory == 2) {
            let chakan = this.$router.resolve({
              path: '/home/resident/addRetail',      //跳转目标窗口的地址
              query: {id: row.id, titleStatus: this.titleStatus} 
            })
            window.open(chakan.href);
          } else if (row.taskCategory == 3) {
            let chakan = this.$router.resolve({
              path: '/home/resident/addAbnormal',      //跳转目标窗口的地址
              query: {id: row.id, titleStatus: this.titleStatus} 
            })
            window.open(chakan.href);
          }
        }
      }

      
      if (val == 2 && row.finishType == 2) {
        this.editConductVisible = true;
        arumTaskSelectTaskById({ taskId: row.id }).then((res) => {
            arumTaskTemplatePulldown({userType: res.data.userType}).then(innerRes => { // 采集模板
                this.colList = innerRes.data
            })
            arumTaskScuTemplatePulldown({userType: res.data.userType}).then(innerRes => { // 安检模板
                this.checkList = innerRes.data
            })
          this.editConductform = res.data;

          // let endTimeNew =  this.getdatecalculate(res.data.endTime)
          this.editConductTimes = [res.data.startTime.replace(/\//g,'-'), res.data.endTime.replace(/\//g,'-')];
          this.value1 = res.data.endTime;
        });
      } else {
        this.titleStatus = val;
        let getDateNumber
        // this.dialogVisible = true;
        if (
          this.titleStatus == 3 ||
          (this.titleStatus == 2 && row.finishType == 1)
        ) {
          // this.$refs.desList.getDesc(row.id);
          getDateNumber = row.id
        }
        if (this.titleStatus == 1) {
          // this.$refs.desList.getDesc();
          getDateNumber = ''
        }
        // let chakan = this.$router.resolve({
				// 	path: '/home/resident/reAdd',      //跳转目标窗口的地址
				// 	query: { id: getDateNumber ,titleStatus:this.titleStatus} 
				// })
				// window.open(chakan.href);
      }
    },
    /**
     * 返回时间减去一天
     */
    getdatecalculate(data){
      // 获取当前时间
      let currentDate = new Date(data);
      
      // 减去一天
      currentDate.setDate(currentDate.getDate() - 1);
      
      // 格式化为 YYYY-MM-DD
      let year = currentDate.getFullYear();
      let month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
      let day = currentDate.getDate().toString().padStart(2, '0');
      
      let formattedDate = year + '-' + month + '-' + day;
      console.log(formattedDate)
      return formattedDate
    },
    setDate(val) {
      // this.editConductform.startTime = val ? this.editConductTimes[0] : '';
      if (val) {
        let endTime
        // 开始时间大于选择的结束时间，结束时间就是开始时间
        if (new Date(this.editConductform.startTime.replace(/\//g,'-')).getTime() > new Date(val[1]).getTime()) {
          endTime = this.editConductform.startTime.replace(/\//g,'-')
        } else {
          endTime = val[1]
        }
        this.editConductTimes = [this.editConductform.startTime.replace(/\//g,'-'),endTime]
      } else {
        this.editConductform.startTime = '';
        this.editConductform.endTime = '';
      }
    },
    /**
     * 编辑任务
     */
    saveData() {
      this.$refs["editConductform"].validate((valid) => {
        if (valid) {
          let data = {
            startTime: this.editConductform.startTime,	// 任务时间(开始时间)
            endTime: this.editConductTimes[1],//this.editConductform.endTime,	// 任务时间(结束时间)
            id: this.editConductform.id,	// 任务id
            inspectorIds: this.editConductform.inspectorIds,	// 安检员id数组
            scuTplId: this.editConductform.scuTplId,	// 安检模板id
            taskName: this.editConductform.taskName,	// 任务名称
            tplId: this.editConductform.tplId,	// 采集模板id
          };
          arumTaskTaskUpdate(data).then((res) => {
            if (res.code == 200) {
              this.$message.success(res.data);
              this.getList(1);
              this.closeEdit();
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      });
    },
    closeEdit() {
      this.editConductform = {};
      this.editConductVisible = false;
      this.editConductTimes = [];
      if (this.$refs.editConductform) {
        this.$refs.editConductform.resetFields();
      }
    },
    /**
     * 更换条数
     */
    handleSizeChange(val) {
      this.search.current = 1;
      this.search.size = val;
      this.getList();
    },
    /**
     * 更换页码
     */
    handleCurrentChange(val) {
      this.search.current = val;
      this.getList();
    },
    /**
     * 删除功能
     */
    delItem(row) {
      this.$confirm("是否确认删除", "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal: false,
        type: "warning",
      }).then(() => {
        arumTaskTaskDeleteById({ taskId: row.id }).then((res) => {
          this.getList();
          this.$message.success("删除成功");
        });
      });
    },
    /**
     * 重置
     */
    reset() {
      this.search = {
        current: 1,
        size: 100,
      };
      this.zhouqiId = []
      this.taskTypes = []
      this.getList();
    },
    close(val) {
      this.dialogVisible = !this.dialogVisible; //监听值发生变化就关闭它
      if (!this.dialogVisible) {
        this.getList(1);
      }
    },
  },
};
</script>
 
<style scoped lang="less">
.table {
  padding: 10px;
  border-radius: 5px;
  margin: 10px;
  background-color: #ffffff;
}
.all {
  background-color: #f3f4f8;
  height: auto;
}
.content {
  background: #f3f4f8;
  width: 100%;
  border-radius: 6px;
  height: calc(100vh - 150px);
}
.choseContain {
  display: flex;
  flex-direction: column;
  button {
    margin-left: 0;
  }
  button:not(:last-child) {
    margin-bottom: 20px;
  }
}
.custom-dialog {
  /deep/.el-dialog__header {
    background-color: #F9F9F9;
    padding: 20px;
    font-weight: bold;
    span:not(.el-dialog__title) {
      font-size: 16px;
    }
    span:not(.el-dialog__title):before {
      content: '';
      display: inline-block;
      width: 4px;
      height: 16px;
      background: #1082FF;
      margin-right: 10px;
      vertical-align: middle;
    }
  }
}
</style>